<template lang="pug">
h1 Checkout
div#checkout
	Form#checkout(@submit="doCheckout" :validation-schema="checkoutSchema")
		fieldset
			legend Customer Status
			div
				label.req(for="statusInd") Individual
				Field#statusInd(name="status" type="radio" v-bind:value="false" v-model="organisation")
			div
				label.req(for="statusOrg") Organisation
				Field#statusOrg(name="status" type="radio" v-bind:value="true" v-model="organisation")
		fieldset
			legend Contact Details
			div
				label.req(for="firstName") First Name
				Field#firstName(name="firstName" type="text")
				ErrorMessage(name="firstName")
			div
				label.req(for="lastName") Last Name
				Field#lastName(name="lastName" type="text")
				ErrorMessage(name="lastName")
			div
				label.req(for="email") Email
				Field#email(name="email" type="email")
				ErrorMessage(name="email")
		fieldset
			legend Company Details
			div
				label.req(for="companyName") Company Name
				Field#companyName(name="companyName" type="text")
				ErrorMessage(name="companyName")
			div
				label.req(for="companyReg") Registration Number
				Field#companyReg(name="companyReg" type="text")
				ErrorMessage(name="companyReg")
			div
				label.req(for="companyTax") VAT Number
				Field#companyTax(name="companyTax" type="text")
				ErrorMessage(name="companyTax")
		fieldset.card
			legend Card Details
			p We do not store your card details; they are passed to our payment provider for secure storage.
				a(role="button") Read more&hellip;
			p(role="" v-show="stripeMore") &hellip;Our payment provider is certified to PCI Service Provider Level 1, the most stringent level of certification available within the payments industry.
		fieldset
			legend Delivery Address
			div
				label.req(for="shipAddress") Address
				Field#shipAddress(name="shipAddress" type="text")
				ErrorMessage(name="shipAddress")
			div
				label.req(for="shipTown") Town
				Field#shipTown(name="shipTown" type="text")
				ErrorMessage(name="shipTown")
			div
				label.req(for="shipCounty") County
				Field#shipCounty(name="shipCounty" type="text")
				ErrorMessage(name="shipCounty")
			div
				label.req(for="shipCountry") Country
				Field#shipCountry(name="shipCountry" as="select" v-model="shipCountry")
					option(v-for="c in countries" :value="c.code") {{c.country}}
			div
				label.req(for="shipPostcode") Post Code
				Field#shipPostcode(name="shipPostcode")
				ErrorMessage(name="shipPostcode")
			div
				input#useBilling(name="useBilling" type="checkbox" v-model="sameAddress" :checked="sameAddress")
				label(for="useBilling") Use delivery address as billing address
		fieldset.address(v-if="!sameAddress")
			legend Billing Address
			div
				label.req(for="billAddress") Address
				Field#billAddress(name="billAddress" type="text")
				ErrorMessage(name="billAddress")
			div
				label.req(for="billTown") Town
				Field#billTown(name="billTown" type="text")
				ErrorMessage(name="billTown")
			div
				label.req(for="billCounty") County
				Field#billCounty(name="billCounty" type="text")
				ErrorMessage(name="billCounty")
			div
				label.req(for="billCountry") Country
				Field#billCountry(name="billCountry" as="select" v-model="billCountry")
					option(v-for="c in countries" :value="c.code") {{c.country}}
			div
				label.req(for="billPostcode") Post Code
				Field#billPostcode(name="billPostcode")
				ErrorMessage(name="billPostcode")
		div
			button#submit(type="submit") Submit
</template>

<script>
const countries = [
	{ code: 'AF', country: 'Afghanistan', zone: 'row' },
	{ code: 'AX', country: 'Aland Islands', zone: 'row' },
	{ code: 'AL', country: 'Albania', zone: 'row' },
	{ code: 'DZ', country: 'Algeria', zone: 'row' },
	{ code: 'AS', country: 'American Samoa', zone: 'row' },
	{ code: 'AD', country: 'Andorra', zone: 'row' },
	{ code: 'AO', country: 'Angola', zone: 'row' },
	{ code: 'AI', country: 'Anguilla', zone: 'row' },
	{ code: 'AQ', country: 'Antarctica', zone: 'row' },
	{ code: 'AG', country: 'Antigua and Barbuda', zone: 'row' },
	{ code: 'AR', country: 'Argentina', zone: 'row' },
	{ code: 'AM', country: 'Armenia', zone: 'row' },
	{ code: 'AW', country: 'Aruba', zone: 'row' },
	{ code: 'AU', country: 'Australia', zone: 'row' },
	{ code: 'AT', country: 'Austria', zone: 'eu' },
	{ code: 'AZ', country: 'Azerbaijan', zone: 'row' },
	{ code: 'BS', country: 'Bahamas', zone: 'row' },
	{ code: 'BH', country: 'Bahrain', zone: 'row' },
	{ code: 'BD', country: 'Bangladesh', zone: 'row' },
	{ code: 'BB', country: 'Barbados', zone: 'row' },
	{ code: 'BY', country: 'Belarus', zone: 'row' },
	{ code: 'BE', country: 'Belgium', zone: 'eu' },
	{ code: 'BZ', country: 'Belize', zone: 'row' },
	{ code: 'BJ', country: 'Benin', zone: 'row' },
	{ code: 'BM', country: 'Bermuda', zone: 'row' },
	{ code: 'BT', country: 'Bhutan', zone: 'row' },
	{ code: 'BO', country: 'Bolivia, Plurinational State of', zone: 'row' },
	{ code: 'BQ', country: 'Bonaire, Sint Eustatius and Saba', zone: 'row' },
	{ code: 'BA', country: 'Bosnia and Herzegovina', zone: 'row' },
	{ code: 'BW', country: 'Botswana', zone: 'row' },
	{ code: 'BV', country: 'Bouvet Island', zone: 'row' },
	{ code: 'BR', country: 'Brazil', zone: 'row' },
	{ code: 'IO', country: 'British Indian Ocean Territory', zone: 'row' },
	{ code: 'BN', country: 'Brunei Darussalam', zone: 'row' },
	{ code: 'BG', country: 'Bulgaria', zone: 'eu' },
	{ code: 'BF', country: 'Burkina Faso', zone: 'row' },
	{ code: 'BI', country: 'Burundi', zone: 'row' },
	{ code: 'KH', country: 'Cambodia', zone: 'row' },
	{ code: 'CM', country: 'Cameroon', zone: 'row' },
	{ code: 'CA', country: 'Canada', zone: 'row' },
	{ code: 'CV', country: 'Cape Verde', zone: 'row' },
	{ code: 'KY', country: 'Cayman Islands', zone: 'row' },
	{ code: 'CF', country: 'Central African Republic', zone: 'row' },
	{ code: 'TD', country: 'Chad', zone: 'row' },
	{ code: 'CL', country: 'Chile', zone: 'row' },
	{ code: 'CN', country: 'China', zone: 'row' },
	{ code: 'CX', country: 'Christmas Island', zone: 'row' },
	{ code: 'CC', country: 'Cocos (Keeling) Islands', zone: 'row' },
	{ code: 'CO', country: 'Colombia', zone: 'row' },
	{ code: 'KM', country: 'Comoros', zone: 'row' },
	{ code: 'CG', country: 'Congo', zone: 'row' },
	{ code: 'CD', country: 'Congo, the Democratic Republic of the', zone: 'row' },
	{ code: 'CK', country: 'Cook Islands', zone: 'row' },
	{ code: 'CR', country: 'Costa Rica', zone: 'row' },
	{ code: 'CI', country: 'Côte d\'Ivoire', zone: 'row' },
	{ code: 'HR', country: 'Croatia', zone: 'eu' },
	{ code: 'CU', country: 'Cuba', zone: 'row' },
	{ code: 'CW', country: 'Curaçao', zone: 'row' },
	{ code: 'CY', country: 'Cyprus', zone: 'eu' },
	{ code: 'CZ', country: 'Czech Republic', zone: 'eu' },
	{ code: 'DK', country: 'Denmark', zone: 'eu' },
	{ code: 'DJ', country: 'Djibouti', zone: 'row' },
	{ code: 'DM', country: 'Dominica', zone: 'row' },
	{ code: 'DO', country: 'Dominican Republic', zone: 'row' },
	{ code: 'EC', country: 'Ecuador', zone: 'row' },
	{ code: 'EG', country: 'Egypt', zone: 'row' },
	{ code: 'SV', country: 'El Salvador', zone: 'row' },
	{ code: 'GQ', country: 'Equatorial Guinea', zone: 'row' },
	{ code: 'ER', country: 'Eritrea', zone: 'row' },
	{ code: 'EE', country: 'Estonia', zone: 'eu' },
	{ code: 'ET', country: 'Ethiopia', zone: 'row' },
	{ code: 'FK', country: 'Falkland Islands (Malvinas)', zone: 'row' },
	{ code: 'FO', country: 'Faroe Islands', zone: 'row' },
	{ code: 'FJ', country: 'Fiji', zone: 'row' },
	{ code: 'FI', country: 'Finland', zone: 'eu' },
	{ code: 'FR', country: 'France', zone: 'eu' },
	{ code: 'GF', country: 'French Guiana', zone: 'row' },
	{ code: 'PF', country: 'French Polynesia', zone: 'row' },
	{ code: 'TF', country: 'French Southern Territories', zone: 'row' },
	{ code: 'GA', country: 'Gabon', zone: 'row' },
	{ code: 'GM', country: 'Gambia', zone: 'row' },
	{ code: 'GE', country: 'Georgia', zone: 'row' },
	{ code: 'DE', country: 'Germany', zone: 'eu' },
	{ code: 'GH', country: 'Ghana', zone: 'row' },
	{ code: 'GI', country: 'Gibraltar', zone: 'row' },
	{ code: 'GR', country: 'Greece', zone: 'eu' },
	{ code: 'GL', country: 'Greenland', zone: 'row' },
	{ code: 'GD', country: 'Grenada', zone: 'row' },
	{ code: 'GP', country: 'Guadeloupe', zone: 'row' },
	{ code: 'GU', country: 'Guam', zone: 'row' },
	{ code: 'GT', country: 'Guatemala', zone: 'row' },
	{ code: 'GG', country: 'Guernsey', zone: 'row' },
	{ code: 'GN', country: 'Guinea', zone: 'row' },
	{ code: 'GW', country: 'Guinea-Bissau', zone: 'row' },
	{ code: 'GY', country: 'Guyana', zone: 'row' },
	{ code: 'HT', country: 'Haiti', zone: 'row' },
	{ code: 'HM', country: 'Heard Island and McDonald Islands', zone: 'row' },
	{ code: 'VA', country: 'Holy See (Vatican City State)', zone: 'row' },
	{ code: 'HN', country: 'Honduras', zone: 'row' },
	{ code: 'HK', country: 'Hong Kong', zone: 'row' },
	{ code: 'HU', country: 'Hungary', zone: 'eu' },
	{ code: 'IS', country: 'Iceland', zone: 'row' },
	{ code: 'IN', country: 'India', zone: 'row' },
	{ code: 'ID', country: 'Indonesia', zone: 'row' },
	{ code: 'IR', country: 'Iran, Islamic Republic of', zone: 'row' },
	{ code: 'IQ', country: 'Iraq', zone: 'row' },
	{ code: 'IE', country: 'Ireland', zone: 'eu' },
	{ code: 'IM', country: 'Isle of Man', zone: 'row' },
	{ code: 'IL', country: 'Israel', zone: 'row' },
	{ code: 'IT', country: 'Italy', zone: 'eu' },
	{ code: 'JM', country: 'Jamaica', zone: 'row' },
	{ code: 'JP', country: 'Japan', zone: 'row' },
	{ code: 'JE', country: 'Jersey', zone: 'row' },
	{ code: 'JO', country: 'Jordan', zone: 'row' },
	{ code: 'KZ', country: 'Kazakhstan', zone: 'row' },
	{ code: 'KE', country: 'Kenya', zone: 'row' },
	{ code: 'KI', country: 'Kiribati', zone: 'row' },
	{ code: 'KP', country: 'Korea, Democratic People\'s Republic of', zone: 'row' },
	{ code: 'KR', country: 'Korea, Republic of', zone: 'row' },
	{ code: 'KW', country: 'Kuwait', zone: 'row' },
	{ code: 'KG', country: 'Kyrgyzstan', zone: 'row' },
	{ code: 'LA', country: 'Lao People\'s Democratic Republic', zone: 'row' },
	{ code: 'LV', country: 'Latvia', zone: 'eu' },
	{ code: 'LB', country: 'Lebanon', zone: 'row' },
	{ code: 'LS', country: 'Lesotho', zone: 'row' },
	{ code: 'LR', country: 'Liberia', zone: 'row' },
	{ code: 'LY', country: 'Libya', zone: 'row' },
	{ code: 'LI', country: 'Liechtenstein', zone: 'row' },
	{ code: 'LT', country: 'Lithuania', zone: 'eu' },
	{ code: 'LU', country: 'Luxembourg', zone: 'eu' },
	{ code: 'MO', country: 'Macao', zone: 'row' },
	{ code: 'MK', country: 'Macedonia, the Former Yugoslav Republic of', zone: 'row' },
	{ code: 'MG', country: 'Madagascar', zone: 'row' },
	{ code: 'MW', country: 'Malawi', zone: 'row' },
	{ code: 'MY', country: 'Malaysia', zone: 'row' },
	{ code: 'MV', country: 'Maldives', zone: 'row' },
	{ code: 'ML', country: 'Mali', zone: 'row' },
	{ code: 'MT', country: 'Malta', zone: 'eu' },
	{ code: 'MH', country: 'Marshall Islands', zone: 'row' },
	{ code: 'MQ', country: 'Martinique', zone: 'row' },
	{ code: 'MR', country: 'Mauritania', zone: 'row' },
	{ code: 'MU', country: 'Mauritius', zone: 'row' },
	{ code: 'YT', country: 'Mayotte', zone: 'row' },
	{ code: 'MX', country: 'Mexico', zone: 'row' },
	{ code: 'FM', country: 'Micronesia, Federated States of', zone: 'row' },
	{ code: 'MD', country: 'Moldova, Republic of', zone: 'row' },
	{ code: 'MC', country: 'Monaco', zone: 'row' },
	{ code: 'MN', country: 'Mongolia', zone: 'row' },
	{ code: 'ME', country: 'Montenegro', zone: 'row' },
	{ code: 'MS', country: 'Montserrat', zone: 'row' },
	{ code: 'MA', country: 'Morocco', zone: 'row' },
	{ code: 'MZ', country: 'Mozambique', zone: 'row' },
	{ code: 'MM', country: 'Myanmar', zone: 'row' },
	{ code: 'NA', country: 'Namibia', zone: 'row' },
	{ code: 'NR', country: 'Nauru', zone: 'row' },
	{ code: 'NP', country: 'Nepal', zone: 'row' },
	{ code: 'NL', country: 'Netherlands', zone: 'eu' },
	{ code: 'NC', country: 'New Caledonia', zone: 'row' },
	{ code: 'NZ', country: 'New Zealand', zone: 'row' },
	{ code: 'NI', country: 'Nicaragua', zone: 'row' },
	{ code: 'NE', country: 'Niger', zone: 'row' },
	{ code: 'NG', country: 'Nigeria', zone: 'row' },
	{ code: 'NU', country: 'Niue', zone: 'row' },
	{ code: 'NF', country: 'Norfolk Island', zone: 'row' },
	{ code: 'MP', country: 'Northern Mariana Islands', zone: 'row' },
	{ code: 'NO', country: 'Norway', zone: 'row' },
	{ code: 'OM', country: 'Oman', zone: 'row' },
	{ code: 'PK', country: 'Pakistan', zone: 'row' },
	{ code: 'PW', country: 'Palau', zone: 'row' },
	{ code: 'PS', country: 'Palestine, State of', zone: 'row' },
	{ code: 'PA', country: 'Panama', zone: 'row' },
	{ code: 'PG', country: 'Papua New Guinea', zone: 'row' },
	{ code: 'PY', country: 'Paraguay', zone: 'row' },
	{ code: 'PU', country: 'Peru', zone: 'row' },
	{ code: 'PH', country: 'Philippines', zone: 'row' },
	{ code: 'PN', country: 'Pitcairn', zone: 'row' },
	{ code: 'PL', country: 'Poland', zone: 'eu' },
	{ code: 'PT', country: 'Portugal', zone: 'eu' },
	{ code: 'PR', country: 'Puerto Rico', zone: 'row' },
	{ code: 'QA', country: 'Qatar', zone: 'row' },
	{ code: 'RE', country: 'Réunion', zone: 'row' },
	{ code: 'RO', country: 'Romania', zone: 'eu' },
	{ code: 'RU', country: 'Russian Federation', zone: 'row' },
	{ code: 'RW', country: 'Rwanda', zone: 'row' },
	{ code: 'BL', country: 'Saint Barthélemy', zone: 'row' },
	{ code: 'SH', country: 'Saint Helena, Ascension and Tristan da Cunha', zone: 'row' },
	{ code: 'KN', country: 'Saint Kitts and Nevis', zone: 'row' },
	{ code: 'LC', country: 'Saint Lucia', zone: 'row' },
	{ code: 'MF', country: 'Saint Martin (French part)', zone: 'row' },
	{ code: 'PM', country: 'Saint Pierre and Miquelon', zone: 'row' },
	{ code: 'VC', country: 'Saint Vincent and the Grenadines', zone: 'row' },
	{ code: 'WS', country: 'Samoa', zone: 'row' },
	{ code: 'SM', country: 'San Marino', zone: 'row' },
	{ code: 'ST', country: 'Sao Tome and Principe', zone: 'row' },
	{ code: 'SA', country: 'Saudi Arabia', zone: 'row' },
	{ code: 'SN', country: 'Senegal', zone: 'row' },
	{ code: 'RS', country: 'Serbia', zone: 'row' },
	{ code: 'SC', country: 'Seychelles', zone: 'row' },
	{ code: 'SL', country: 'Sierra Leone', zone: 'row' },
	{ code: 'SG', country: 'Singapore', zone: 'row' },
	{ code: 'SX', country: 'Sint Maarten (Dutch part)', zone: 'row' },
	{ code: 'SK', country: 'Slovakia', zone: 'eu' },
	{ code: 'SI', country: 'Slovenia', zone: 'eu' },
	{ code: 'SB', country: 'Solomon Islands', zone: 'row' },
	{ code: 'SO', country: 'Somalia', zone: 'row' },
	{ code: 'ZA', country: 'South Africa', zone: 'row' },
	{ code: 'GS', country: 'South Georgia and the South Sandwich Islands', zone: 'row' },
	{ code: 'SS', country: 'South Sudan', zone: 'row' },
	{ code: 'ES', country: 'Spain', zone: 'eu' },
	{ code: 'LK', country: 'Sri Lanka', zone: 'row' },
	{ code: 'SD', country: 'Sudan', zone: 'row' },
	{ code: 'SR', country: 'Suriname', zone: 'row' },
	{ code: 'SJ', country: 'Svalbard and Jan Mayen', zone: 'row' },
	{ code: 'SZ', country: 'Swaziland', zone: 'row' },
	{ code: 'SE', country: 'Sweden', zone: 'eu' },
	{ code: 'CH', country: 'Switzerland', zone: 'row' },
	{ code: 'SY', country: 'Syrian Arab Republic', zone: 'row' },
	{ code: 'TW', country: 'Taiwan, Province of China', zone: 'row' },
	{ code: 'TJ', country: 'Tajikistan', zone: 'row' },
	{ code: 'TZ', country: 'Tanzania, United Republic of', zone: 'row' },
	{ code: 'TH', country: 'Thailand', zone: 'row' },
	{ code: 'TL', country: 'Timor-Leste', zone: 'row' },
	{ code: 'TG', country: 'Togo', zone: 'row' },
	{ code: 'TK', country: 'Tokelau', zone: 'row' },
	{ code: 'TO', country: 'Tonga', zone: 'row' },
	{ code: 'TT', country: 'Trinidad and Tobago', zone: 'row' },
	{ code: 'TN', country: 'Tunisia', zone: 'row' },
	{ code: 'TR', country: 'Turkey', zone: 'row' },
	{ code: 'TM', country: 'Turkmenistan', zone: 'row' },
	{ code: 'TC', country: 'Turks and Caicos Islands', zone: 'row' },
	{ code: 'TV', country: 'Tuvalu', zone: 'row' },
	{ code: 'UG', country: 'Uganda', zone: 'row' },
	{ code: 'UA', country: 'Ukraine', zone: 'row' },
	{ code: 'UE', country: 'United Arab Emirates', zone: 'row' },
	{ code: 'GB', country: 'United Kingdom', zone: 'uk' },
	{ code: 'US', country: 'United States', zone: 'row' },
	{ code: 'UM', country: 'United States Minor Outlying Islands', zone: 'row' },
	{ code: 'UY', country: 'Uruguay', zone: 'row' },
	{ code: 'UZ', country: 'Uzbekistan', zone: 'row' },
	{ code: 'VU', country: 'Vanuatu', zone: 'row' },
	{ code: 'VE', country: 'Venezuela, Bolivarian Republic of', zone: 'row' },
	{ code: 'VN', country: 'Viet Nam', zone: 'row' },
	{ code: 'VG', country: 'Virgin Islands, British', zone: 'row' },
	{ code: 'VI', country: 'Virgin Islands, U.S.', zone: 'row' },
	{ code: 'WF', country: 'Wallis and Futuna', zone: 'row' },
	{ code: 'EH', country: 'Western Sahara', zone: 'row' },
	{ code: 'YE', country: 'Yemen', zone: 'row' },
	{ code: 'ZM', country: 'Zambia', zone: 'row' },
	{ code: 'ZW', country: 'Zimbabwe', zone: 'row' },
	{ code: '', country: '', zone: 'row' },
];

import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
	name: 'Checkout',
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const req = 'Required Field';
		const checkoutSchema = yup.object().shape({
			email: yup.string().email().required(req),
			firstName: yup.string().required(req),
			lastName: yup.string().required(req),
			companyName: yup.string().required(req),
			billAddress: yup.string().required(req),
			billTown: yup.string().required(req),
			billCounty: yup.string().required(req),
			billCountry: yup.string().required(req),
			billPostcode: yup.string().required(req),
			shipAddress: yup.string().required(req),
			shipTown: yup.string().required(req),
			shipCounty: yup.string().required(req),
			shipCountry: yup.string().required(req),
			shipPostcode: yup.string().required(req),
			// password: yup.string().min(8).required('Password is required')
		});
		
		return {
			checkoutSchema,
			loading: false,
			message: '',
			countries : countries,
			sameAddress: true,
			billCountry: 'GB',
			shipCountry: 'GB',
			organisation: false
		};
	},	
	computed: {
	},
	methods: {
		doCheckout(formData) {
			this.loading = true;
			console.log(formData);
//			const data = {
//				country: countries[formData.country]				
//			}			
		},
	},
};
</script>

<style lang="scss">
</style>